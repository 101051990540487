var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-page" }, [
    _vm.isUserAlreadyLoggedIn
      ? _c(
          "div",
          { staticClass: "already-logged-in" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$t("login.already-logged-in-title")))]),
            _c("p", { staticClass: "already-logged-in" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("login.already-logged-in-text")) + " "
              ),
              _c("span", { staticClass: "username" }, [
                _vm._v(_vm._s(_vm.existingUsername)),
              ]),
            ]),
            _c(
              "Button",
              {
                staticClass: "login-button",
                attrs: { click: _vm.stayLoggedIn },
              },
              [_vm._v(" " + _vm._s(_vm.$t("login.proceed-to-dashboard")) + " ")]
            ),
            _c(
              "Button",
              { staticClass: "login-button", attrs: { click: _vm.getOut } },
              [_vm._v(_vm._s(_vm.$t("login.log-out-button")))]
            ),
            _c("span", { staticClass: "already-logged-in-note" }, [
              _vm._v(
                " You need to log out, in order to proceed as a different user. "
              ),
            ]),
            _c("transition", { attrs: { name: "bounce" } }, [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.message,
                      expression: "message",
                    },
                  ],
                  class: `login-error-message ${_vm.status}`,
                },
                [_vm._v(_vm._s(_vm.message))]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    !_vm.isUserAlreadyLoggedIn && _vm.isAuthenticationEnabled
      ? _c(
          "form",
          { staticClass: "login-form" },
          [
            _c("h2", { staticClass: "login-title" }, [
              _vm._v(_vm._s(_vm.$t("login.title"))),
            ]),
            _c("Input", {
              staticClass: "login-field username",
              attrs: {
                type: "text",
                onEnter: _vm.submitLogin,
                label: _vm.$t("login.username-label"),
              },
              model: {
                value: _vm.username,
                callback: function ($$v) {
                  _vm.username = $$v
                },
                expression: "username",
              },
            }),
            _c("Input", {
              staticClass: "login-field password",
              attrs: {
                type: "password",
                onEnter: _vm.submitLogin,
                label: _vm.$t("login.password-label"),
              },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
            _c("label", [_vm._v(_vm._s(_vm.$t("login.remember-me-label")))]),
            _c("v-select", {
              staticClass: "login-time-dropdown",
              attrs: {
                selectOnTab: true,
                options: _vm.dropDownMenu,
                "map-keydown": (map) => ({
                  ...map,
                  13: () => this.submitLogin(),
                }),
              },
              model: {
                value: _vm.timeout,
                callback: function ($$v) {
                  _vm.timeout = $$v
                },
                expression: "timeout",
              },
            }),
            _c(
              "Button",
              {
                staticClass: "login-button",
                attrs: { click: _vm.submitLogin },
              },
              [_vm._v(" " + _vm._s(_vm.$t("login.login-button")) + " ")]
            ),
            _c("transition", { attrs: { name: "bounce" } }, [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.message,
                      expression: "message",
                    },
                  ],
                  class: `login-error-message ${_vm.status}`,
                },
                [_vm._v(_vm._s(_vm.message))]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.isGuestAccessEnabled &&
    !_vm.isUserAlreadyLoggedIn &&
    _vm.isAuthenticationEnabled
      ? _c(
          "form",
          { staticClass: "guest-form" },
          [
            _c("h2", { staticClass: "login-title" }, [
              _vm._v(_vm._s(_vm.$t("login.guest-label"))),
            ]),
            _c(
              "Button",
              { staticClass: "login-button", attrs: { click: _vm.guestLogin } },
              [_vm._v(" " + _vm._s(_vm.$t("login.proceed-guest-button")) + " ")]
            ),
            _c("p", { staticClass: "guest-intro" }, [
              _vm._v(" " + _vm._s(_vm.$t("login.guest-intro-1"))),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("login.guest-intro-2")) + " "),
            ]),
          ],
          1
        )
      : _vm._e(),
    !_vm.isAuthenticationEnabled
      ? _c(
          "div",
          { staticClass: "not-configured" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$t("login.error")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("login.error-no-user-configured")))]),
            _c(
              "Button",
              { staticClass: "login-button", attrs: { click: _vm.guestLogin } },
              [_vm._v(" " + _vm._s(_vm.$t("login.error-go-home-button")) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }